/**************************************************
 * bootstrap/mdb variable overrides
 **************************************************/
$breadcrumb-divider: "";
$primary-color: #19a4dd;
$secondary-color: #0f5e93;
$link-color: #0f5e93;
$link-hover-decoration: none;
$link-hover-color: #19a4dd;
$enable-caret: true;

/**************************************************
 * import bootstrap
 **************************************************/
//@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

/**************************************************
 * import mdbootstrap
 **************************************************/
//@import "~mdbootstrap/scss/mdb";
//@import "~mdbootstrap/scss/core/bootstrap/functions";
//@import "~mdbootstrap/scss/core/bootstrap/variables";
@import "~mdbootstrap/scss/core/mixins";
@import "~mdbootstrap/scss/custom-variables";
@import "~mdbootstrap/scss/core/colors";
@import "~mdbootstrap/scss/core/variables";
@import "~mdbootstrap/scss/core/global";
@import "~mdbootstrap/scss/core/helpers";
@import "~mdbootstrap/scss/core/typography";
@import "~mdbootstrap/scss/core/masks";
@import "~mdbootstrap/scss/core/waves";
@import "~mdbootstrap/scss/free/animations-basic";
//@import "~mdbootstrap/scss/free/animations-extended";
@import "~mdbootstrap/scss/free/buttons";
@import "~mdbootstrap/scss/free/cards";
@import "~mdbootstrap/scss/free/dropdowns";
@import "~mdbootstrap/scss/free/input-group";
//@import "~mdbootstrap/scss/free/navbars";
@import "~mdbootstrap/scss/free/pagination";
@import "~mdbootstrap/scss/free/badges";
@import "~mdbootstrap/scss/free/modals";
@import "~mdbootstrap/scss/free/carousels";
@import "~mdbootstrap/scss/free/forms";
@import "~mdbootstrap/scss/free/msc";
@import "~mdbootstrap/scss/free/footers";
@import "~mdbootstrap/scss/free/list-group";
@import "~mdbootstrap/scss/free/tables";
@import "~mdbootstrap/scss/free/switch";
@import "~mdbootstrap/scss/free/depreciated";
@import "~mdbootstrap/scss/custom-styles";

/**************************************************
 * import fontawesome 5 pro
 **************************************************/
@import "../../../fontawesome-pro-5.13.1-web/scss/fontawesome";
@import "../../../fontawesome-pro-5.13.1-web/scss/solid";
@import "../../../fontawesome-pro-5.13.1-web/scss/regular";
@import "../../../fontawesome-pro-5.13.1-web/scss/brands";
@import "../../../fontawesome-pro-5.13.1-web/scss/light";
@import "../../../../../node_modules/ekko-lightbox/dist/ekko-lightbox.css";

@import "_variables.scss";
@import "_mixins.scss";
@import "_customs.scss";

/**************************************************
 * bootstrap/mdb style overrides
 **************************************************/
.overlay {
  cursor: pointer;
}

.btn-floating {
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.navbar-nav li:hover > ul.dropdown-menu {
  display: block;
}

.navbar-nav {
  li {
    display: flex;
    align-items: center;
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  margin-left: 100%;
  margin-top: -9px;
}

.dropdown-menu > li > a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
}

/**************************************************
 * global font face
 **************************************************/
body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

/**************************************************
 * push wrapper down when navbar is fixed to top
 **************************************************/
header {
  padding-top: 75px;
}

/**************************************************
 * push footer down when main is empty
 **************************************************/
main {
  min-height: 100vh;
}

/**************************************************
 * loading screen
 **************************************************/
.loading-screen {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.5);
  .loader {
    border: 16px solid $light;
    border-top-color: $primary-color;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/**************************************************
 * multilevel navbar
 **************************************************/
.navbar-brand {

}

/**************************************************
 * carousel banner
 **************************************************/
#carousel-banner {
  .carousel-item {
    height: 32rem;
    background: no-repeat center;
    background-size: cover;
    @include media-breakpoint-down(lg) {
      height: 24rem;
    }
    @include media-breakpoint-down(md) {
      height: 16rem;
    }
    > img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      height: 32rem;
      @include media-breakpoint-down(lg) {
        height: 24rem;
      }
      @include media-breakpoint-down(md) {
        height: 16rem;
      }
    }
  }
}

#carousel-banner-simple {
  .carousel-item {
    height: 24rem;
    background: no-repeat center;
    background-size: cover;
    @include media-breakpoint-down(lg) {
      height: 24rem;
    }
    @include media-breakpoint-down(md) {
      height: 16rem;
    }
    > img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      height: 32rem;
      @include media-breakpoint-down(lg) {
        height: 24rem;
      }
      @include media-breakpoint-down(md) {
        height: 16rem;
      }
    }
  }
}

/**************************************************
 * custom css below
 **************************************************/

 header {
  .logo-wrapper {
    .logo-img {
      max-width: 10rem;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 60.53px;

    .logo-wrapper {
      .logo-img {
        max-width: 7rem;
      }
    }
  }

  @media(max-width: 356px) {
    .logo-wrapper {
      .logo-img {
        max-width: 7rem;
      }
    }
  }
 }

.language-wrapper-mobile {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    width: auto;
  }
}

#mobileMenuNav {
  display: none;
}

.mobile-menu-language {
  .dropdown-menu {
    min-width: 4rem;  
  }
}

#searchModal {
  .modal-content {
    background: transparent;
    border-radius: 2rem;

    #keyword {
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
      padding: 1rem 2rem 1rem;
      height: auto;
    }

    .btn {
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }
  }
}

.navbar-hamburger {
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border-radius: 0.25rem;
}

.social-media-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.social-icon-nav {
  flex-wrap: wrap;
}

.g-recaptcha {  
  @media(max-width: 360px) {
    transform:scale(0.86);-webkit-transform:scale(0.86);
  }
}