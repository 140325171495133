.row {
  &.small-gutters {
    margin-right: -0.5rem;
    margin-left: -0.5rem;

    > .col,
    > [class*="col-"] {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}